import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
// GET UPDATED DATA
// this.communicationService.getUserId.subscribe(message => message = this.userId);
// Update Data
// this.communicationService.setUserId(data);
export class CommunicationService {
    constructor(CookieService) {
        this.CookieService = CookieService;
        this.userId = new BehaviorSubject(null);
        this.userName = new BehaviorSubject(null);
        this.adminAuthority = new BehaviorSubject(false);
        this.posterAdminAuthority = new BehaviorSubject(false);
        this.editorGroupAuthority = new BehaviorSubject(false);
        this.journalName = new BehaviorSubject(null);
        // tslint:disable-next-line:member-ordering
        this.getUserId = this.userId.asObservable();
        // tslint:disable-next-line:member-ordering
        this.getUserName = this.userName.asObservable();
        // tslint:disable-next-line:member-ordering
        this.getAdminAuthority = this.adminAuthority.asObservable();
        // tslint:disable-next-line:member-ordering
        this.getPosterAdminAuthority = this.posterAdminAuthority.asObservable();
        this.getEditorGroupAuthority = this.editorGroupAuthority.asObservable();
        // tslint:disable-next-line:member-ordering
        this.getJournalName = this.journalName.asObservable();
        this.initValues();
    }
    initValues() {
        let userName = this.CookieService.get('userName');
        let adminAuthority = this.CookieService.get('adminAuthority');
        let posterAdminAuthority = this.CookieService.get('posterAdminAuthority');
        let editorGroupAuthority = this.CookieService.get('editorGroupAuthority');
        let journalName = this.CookieService.get('journalName');
        if (editorGroupAuthority) {
            let res = JSON.parse(editorGroupAuthority);
            this.editorGroupAuthority.next(res);
        }
        if (adminAuthority) {
            let res = JSON.parse(adminAuthority);
            this.adminAuthority.next(res);
        }
        if (posterAdminAuthority) {
            let res = JSON.parse(posterAdminAuthority);
            this.posterAdminAuthority.next(res);
        }
        let userId = this.CookieService.get('userId');
        if (userId) {
            this.userId.next(userId);
        }
        if (userName) {
            this.userName.next(userName);
        }
        if (journalName) {
            this.journalName.next(journalName);
        }
    }
    setJournalName(journalName) {
        this.journalName.next(journalName);
    }
    setAdminAuthority(val) {
        this.adminAuthority.next(val);
    }
    setPosterAdminAuthority(val) {
        this.posterAdminAuthority.next(val);
    }
    setEditorGroupAuthority(val) {
        this.editorGroupAuthority.next(val);
    }
    setUserId(userId) {
        this.userId.next(userId);
    }
    setUserName(userName) {
        this.userName.next(userName);
    }
    onlogoutUser() {
        this.journalName.next(null);
        this.userName.next(null);
        this.adminAuthority.next(null);
        this.posterAdminAuthority.next(null);
        this.userId.next(null);
    }
}
CommunicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommunicationService_Factory() { return new CommunicationService(i0.ɵɵinject(i1.CookieService)); }, token: CommunicationService, providedIn: "root" });
